.overflow-y-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
}
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: lightblue !important;
}
.bold-form .ant-form-item-label {
  font-weight: bold;
}
.checkout-nav {
  display: block;
}
.content-lay {
  padding: 0px 40px;
}

@media (max-width: 767.98px) {
  .checkout-nav {
    display: none;
  }
  .content-lay {
    padding: 0px 0px;
  }
}

.ant-table-thead > tr > th {
  line-height: normal;
}
.ant-table-tbody > tr > td {
  line-height: normal;
}

.checkout .checkout-div {
  background-color: #f2f2f2;
  padding: 0px 0px;
  margin: 0px;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
}
.checkout .left-panel {
  height: 100vh;
  background-color: #ffffff;
  box-shadow: 20px 22px 63px 3px rgba(0, 0, 0, 0.1);
  padding: 10px;
}
.checkout .left-panel .product-box {
  height: 40%;
  margin-bottom: auto;
}
.checkout .left-panel .product-box .product-subbox {
  padding: 10px;
}

.checkout .left-panel .product-box .product-item {
  width: 120px;
  cursor: pointer;
  background: #2d2d2d26;
  height: 80px;
  flex-direction: column;
}
.checkout .left-panel .product-box .product-item .card-title {
  font-size: 14px;
  font-weight: 600;
  margin-top: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}
.checkout .left-panel .product-below-box {
  height: 60%;
}
.checkout .left-panel .product-below-box .company-search-box {
  padding: 10px;
  justify-content: space-between;
}
.checkout .left-panel .product-below-box .company-box {
  gap: 10px 30px;
  font-size: 16px;
  flex-wrap: wrap;
  width: calc(100% - 180px);
}

.checkout .left-panel .product-below-box .company-box .company-item {
  cursor: pointer;
}

.checkout .left-panel .product-below-box .product-type-box {
  grid-row-gap: 10px;
  margin-bottom: auto;
  padding: 10px;
}
.checkout .left-panel .product-below-box .product-type-box .product-type-item {
  cursor: pointer;
  width: 112px;
  background: #2d2d2d26;
  height: 80px;
}
.checkout .left-panel .product-below-box .product-type-box .product-type-item .product-type-itemp {
  padding: 10px 10px;
}
.checkout .right-panel {
  height: 100%;
  background-color: #ffffff;
  box-shadow: 20px 22px 63px 3px rgba(0, 0, 0, 0.1);
  padding: 20px 10px 10px 10px;
}
.checkout .right-panel .payment-method-box {
  overflow-x: auto;
  white-space: nowrap;
  margin: 5px 0px;
  display: flex;
}
.checkout .right-panel-parent {
  position: unset;
  bottom: 0px;
}
.checkout .right-panel .ordered-box {
  max-height: 300px;
}
.checkout .right-panel .ordered-box .ordered-item {
  margin: 10px 5px;
}
.checkout .right-panel .right-button-box {
  margin-top: auto;
  margin-bottom: 10px;
}
.checkout .right-panel .price-box {
  margin-top: 10px;
  margin-bottom: auto;
}

.checkout .left-panel .searchbar-box {
  width: 180px;
}
.checkout .left-panel .searchbar-box .searchbar-item {
  width: 170px;
}
@media (max-width: 767.98px) {
  .ant-modal-body {
    padding: 10px !important;
  }
  .checkout .checkout-div {
    padding: 0px 0px;
  }
  .checkout .right-panel-parent {
    position: sticky;
    bottom: 0px;
  }
  .checkout .left-panel {
    min-height: 55vh;
    height: auto;
    background-color: #ffffff;
    box-shadow: 20px 22px 63px 3px rgba(0, 0, 0, 0.1);
    padding: 5px;
  }
  .checkout .left-panel .product-box {
    height: 30vh;
    min-height: 100px;
    margin-bottom: auto;
  }
  .checkout .left-panel .product-box .product-subbox {
    padding: 0px 5px;
  }
  .checkout .left-panel .product-box .product-item {
    width: calc(33% - 15px);
    cursor: pointer;
    background: #2d2d2d26;
    /* height: 55px; */
    height: auto;
    flex-direction: column;
  }
  .checkout .left-panel .product-below-box {
    height: 48vh;
  }
  .checkout .left-panel .product-below-box .company-search-box {
    padding: 0px 10px;
    justify-content: space-between;
    min-height: 30px;
  }
  .checkout .left-panel .searchbar-box {
    width: 110px;
  }
  .checkout .left-panel .searchbar-box .searchbar-item {
    width: 100px;
  }
  .checkout .left-panel .product-below-box .product-type-box .product-type-item {
    padding: 0 10px;
    cursor: pointer;
    width: calc(33% - 15px);
    background: #2d2d2d26;
    height: auto;
  }

  .checkout .left-panel .product-below-box .product-type-box .product-type-itemp {
    padding: 4px 10px;
  }

  .checkout .right-panel {
    height: auto;
    background-color: #ffffff;
    box-shadow: 20px 22px 63px 3px rgba(0, 0, 0, 0.3);
    padding: 0px 10px;
  }
  .checkout .right-panel .ordered-box {
    max-height: 120px;
  }
  .checkout .right-panel .ordered-box .ordered-item {
    margin: 5px 5px;
  }

  .checkout .right-panel .price-box {
    margin-top: 0px;
    margin-bottom: auto;
  }
  .checkout .right-panel .payment-method-box {
    margin: 5px 0px;
    overflow-x: auto;
    white-space: nowrap;
    display: block;
  }

  .checkout .right-panel .right-button-box {
    margin-top: auto;
    margin-bottom: 5px;
  }
}

@root-entry-name: default;@primary-color: #1B98F5;@link-color: #1B98F5;@border-radius-base: 4px;@heading-color: #10002b;@text-color: #2D3142;@text-color-secondary: #4F5D75;@disabled-color: #BFC0C0;@background-color-light: rgba(249, 250, 252,1);@background-color-base: rgba(249, 250, 252,0.7);@btn-font-weight: 600;